
import CheckWLType from '~/mixins/checkWLType'
export default {
    name: 'QuotaWarning',
    mixins: [CheckWLType],
    computed: {
        modal: {
            get() {
                // QuotaWarning will not displayed in development mode
                // if (this.$config.DEVELOPMENT) return false
                return this.$store.state.settings.displayRechargeModal
            },
            set(value) {
                this.$store.commit('settings/setDisplayRechargeModal', value)
            },
        },
    },
    mounted() {
        if (this.isInternational) {
            this.$store.dispatch(
                'settings/displayQuotaWarning',
                this.globalDefaultCurrency
            )
        } else {
            // Backend Team, give us this THB Logic
            this.$store.dispatch('settings/displayQuotaWarning', 'THB')
        }
    },
    methods: {
        onCloseModal() {
            const minutes = 30
            const timeInterval = minutes * 60 * 1000 // 30 minutes, 60 seconds, 1000 milliseconds
            this.modal = false
            setTimeout(() => {
                this.modal = true
            }, timeInterval)
        },
    },
}
