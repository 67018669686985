
export default {
    props: {
        defaultChip: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            version: null,
        }
    },
    mounted() {
        // version checking
        this.$cookie.set('version', this.$config.VERSION)
        this.versionChange()
    },
    methods: {
        versionChange() {
            const $this = this
            const oldVersionPlain = $this.$cookie.get('version')
            if (!oldVersionPlain) {
                $this.$cookie.set('version', $this.$config.VERSION)
            }
            const newVersionPlain = $this.$config.VERSION
            const oldVersion = oldVersionPlain
                ? oldVersionPlain.split('.')
                : newVersionPlain
            const newVersion = newVersionPlain.split('.')
            let result = false
            newVersion.forEach((val, index) => {
                if (parseInt(val) > parseInt(oldVersion[index])) {
                    result = true
                }
            })
            if (result) {
                $this.$toast.info(`New update available! v${newVersionPlain}`, {
                    position: 'bottom-left',
                    duration: null,
                    action: {
                        text: 'Refresh',
                        onClick: (e, toastObject) => {
                            $this.$cookie.set('version', newVersionPlain)
                            $this.$toast.success('Thanks for confirming!', {
                                position: 'bottom-left',
                            })
                            setTimeout(() => {
                                $this.$router.go(0)
                            }, 1000)
                        },
                    },
                })
            }
            this.version = 'v' + oldVersionPlain
            return 'v' + oldVersionPlain
        },
    },
}
