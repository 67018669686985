
export default {
    data() {
        return {
            soundOn: true,
        }
    },
    mounted() {
        this.soundOn = this.$cookie.get('sound')
    },
    methods: {
        playNotification(toastText) {
            this.$toast.info(toastText)
            if (this.$cookie.get('sound')) {
                const audio = new Audio('/sounds/notification.mp3')
                audio.play()
            }
        },
        soundToggle() {
            if (this.$cookie.get('sound')) {
                this.$cookie.set('sound', false)
                this.soundOn = false
            } else {
                this.$cookie.set('sound', true)
                this.playNotification(this.tl('soundEnabled'))
                this.soundOn = true
            }
        },
    },
}
