
import { mapState } from 'vuex'
export default {
    name: 'ChangeLanguage',
    data() {
        return {
            loading: true,
        }
    },
    computed: {
        ...mapState({
            languages: (state) => state.master.languages,
        }),
        availableLocales() {
            return this.languages.filter(
                (language) => language.code !== this.systemLocale
            )
        },
    },
    methods: {
        getCurrentLocale(localeCode) {
            if (!localeCode) return null
            const currentLocaleData = this.languages.find(
                (el) => el.code === localeCode
            )
            if (!currentLocaleData) return null
            return currentLocaleData.flag_icon
        },
        async onSwitchLocale(locale) {
            const data = {
                // id: this.$cookie.get('user').id,
                language: locale.code,
            }
            this.$store.commit('master/loadingStringsForTranslation', true)
            await this.$store.dispatch('master/translationStrings', locale.code)
            this.$store.commit('master/loadingStringsForTranslation', false)
            this.$store.dispatch('users/updateUser', data)
            this.$cookie.set('locale', locale.code)
            this.$store.dispatch('settings/locale', locale.code)
        },
    },
}
