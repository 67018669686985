
import { mapGetters, mapState } from 'vuex'
export default {
    name: 'BreadCrumbs',
    data() {
        return {}
    },
    computed: {
        ...mapState({
            customBreadcrumbsItems: (state) =>
                state.settings.customBreadcrumbsItems,
        }),
        ...mapGetters({
            getMenuForBreadcrumbs: 'settings/getMenuForBreadcrumbs',
        }),
        currentPath() {
            const path = this.$route.path
            if (this.getMenuForBreadcrumbs[path]) {
                return {
                    paths: this.getMenuForBreadcrumbs[path],
                    precise: true,
                }
            }
            const splitPath = path.split('/')
            const modifiedPath = splitPath.slice(0, -1).join('/')
            if (this.getMenuForBreadcrumbs[modifiedPath]) {
                const menuBreadcrumbs = this.getMenuForBreadcrumbs[modifiedPath]
                return {
                    paths: menuBreadcrumbs,
                    precise: false,
                }
            }
            const modifiedPathForEdit = splitPath.slice(0, -2).join('/')
            if (this.getMenuForBreadcrumbs[modifiedPathForEdit]) {
                const menuBreadcrumbs =
                    this.getMenuForBreadcrumbs[modifiedPathForEdit]
                return {
                    paths: menuBreadcrumbs,
                    precise: false,
                }
            }
            return {}
        },
        items() {
            if (this.customBreadcrumbsItems.length > 0)
                return this.customBreadcrumbsItems
            if (this.currentPath.precise === true) {
                const breadcrumbs = [
                    {
                        text: this.currentPath.paths.title,
                        disabled: false,
                    },
                ]
                if (this.currentPath?.paths?.parent) {
                    breadcrumbs.unshift({
                        text: this.currentPath.paths.parent,
                        disabled: true,
                    })
                }
                if (this.currentPath?.paths?.grandParent) {
                    breadcrumbs.unshift({
                        text: this.currentPath.paths.grandParent,
                        disabled: true,
                    })
                }
                return breadcrumbs
            }
            if (this.currentPath.precise === false) {
                const path = this.$route.path.split('/')

                const breadcrumbs = [
                    {
                        text: this.currentPath.paths.title,
                        disabled: false,
                        href: this.currentPath.paths.to,
                    },
                ]
                if (this.currentPath?.paths?.parent) {
                    breadcrumbs.unshift({
                        text: this.currentPath.paths.parent,
                        disabled: true,
                    })
                }
                if (this.currentPath?.paths?.grandParent) {
                    breadcrumbs.unshift({
                        text: this.currentPath.paths.grandParent,
                        disabled: true,
                    })
                }
                const pathAfterMainPath = path[breadcrumbs.length + 1]
                const subPath = this.subPath[pathAfterMainPath]
                if (subPath) {
                    breadcrumbs.push({
                        text: subPath,
                        disabled: false,
                    })
                } else {
                    breadcrumbs.push({
                        text: 'detail',
                        disabled: false,
                    })
                }
                return breadcrumbs
            }
            return [{ text: '', disabled: false }]
        },
        subPath() {
            // display the correct text to subpath
            return {
                create: 'create',
                edit: 'edit',
                add: 'create',
                'manual-deposit': 'manualDeposit',
            }
        },
    },
    methods: {
        navigation(link) {
            if (link) {
                this.$router.push(link)
            }
        },
    },
}
