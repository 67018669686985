
export default {
    data() {
        return {
            darkMode: false,
        }
    },
    created() {
        this.darkMode = this.$cookie.get('darkMode') || this.$vuetify.theme.dark
        this.$vuetify.theme.dark = this.$cookie.get('darkMode') || false
    },
    methods: {
        onSwitchMode() {
            this.$vuetify.theme.dark = !this.$vuetify.theme.dark
            this.darkMode = this.$vuetify.theme.dark
            this.$cookie.set('darkMode', this.$vuetify.theme.dark)
        },
    },
}
