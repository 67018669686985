
export default {
    props: {
        depositNotifications: {
            type: Array,
            default: () => [],
        },
        withdrawalNotifications: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        allNotificationList() {
            const notificationList = [
                ...this.depositNotifications,
                ...this.withdrawalNotifications,
            ]
            return notificationList.sort(
                (a, b) => new Date(b.notify_date) - new Date(a.notify_date)
            )
        },
    },
    methods: {
        toggleNotificationMenu(val) {
            this.$emit('toggle', val)
        },
        removeItem(link, index) {
            this.$router.push(link)
        },
    },
}
