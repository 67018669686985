
export default {
    name: 'LoadingLanguageString',
    computed: {
        loadingLanguageString() {
            return this.$store.state.master.loadingStringsForTranslation
        },
        loadingLanguageStringModal: {
            get() {
                return this.loadingLanguageString
            },
            set(value) {
                return value
            },
        },
    },
}
